import * as API from '@/api/index'

export default {
    // 支出类型
    SpendingCategory: params => {
        return API.POST('api/additionalSpendingCategory/getAdditionalSpendingCategory', params)
    },
    // 收入类型
    IncomeCategory: params => {
        return API.POST('api/additionalIncomeCategory/getAdditionalIncomeCategory', params)
    },
    // 支付方式
    Payments: params => {
        return API.POST('api/additionalPayments/getAdditionalPayments', params)
    },
    // 列表查询
    SpendingList: params => {
        return API.POST('api/additionalSpending/list', params)
    },
    // 收入列表查询
    IncomeList: params => {
        return API.POST('api/additionalIncome/list', params)
    },
    // 新增支出
    Incomecreate: params => {
        return API.POST('api/additionalIncome/create', params)
    },
    // 新增收入
    create: params => {
        return API.POST('api/additionalSpending/create', params)
    },
    // 编辑支出
    update: params => {
        return API.POST('api/additionalSpending/update', params)
    },
    // 编辑收入
    IncomeUpdate: params => {
        return API.POST('api/additionalIncome/update', params)
    },
    // 删除支出
    delete: params => {
        return API.POST('api/additionalSpending/delete', params)
    },
    // 删除收入
    IncomeDelete: params => {
        return API.POST('api/additionalIncome/delete', params)
    },
    getEntityAll: params => {
        return API.POST("api/entity/getStoreList", params)
    }
}